@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.caster__levels {
	display: flex;
	justify-content: center;
	column-gap: 3px;
	margin-bottom: 10px;

	.level {
		width: 30px;
		height: 30px;
		line-height: 30px;
		text-align: center;
		cursor: pointer;
		background-color: $neutral-8;
		user-select: none;

		&.selected {
			background-color: $blue;
			color: $neutral-1;
		}
		&.disabled {
			opacity: 0.4;
			cursor: not-allowed;
		}
	}
}
